import React     from 'react';
import PropTypes from 'prop-types';

import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import ContentConsent     from '../../modules/ContentConsent/ContentConsent';

const ResponsiveIFrame = ({ src, title, maxHeight, consentNecessary, consentHeader, consentText, consentButton, type }) => {
  const consentKey = type ? type : `${title.toLowerCase().trim()}`;

  return (
    <ConditionalWrapper condition={consentNecessary}
                        wrapper={children => <ContentConsent consentId={consentKey}
                                                             consentHeader={consentHeader}
                                                             consentText={consentText}
                                                             consentButton={consentButton}
                                                             type={type}>{children}</ContentConsent>}>
      <div style={{ position: 'relative', 'width': '100%', paddingTop: `${maxHeight}%` }}>
        <iframe
          src={src}
          frameBorder='0'
          style={{ border: '0', position: 'absolute', height: '100%', width: '100%', top: '0', left: '0' }}
          allowFullScreen title={title}/>
      </div>
    </ConditionalWrapper>
  )
};

ResponsiveIFrame.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  maxHeight: PropTypes.number,
  consentNecessary: PropTypes.bool,
  consentHeader: PropTypes.string,
  consentText: PropTypes.string,
  consentButton: PropTypes.string,
  type: PropTypes.oneOf(['youtube', 'vimeo']),
};

ResponsiveIFrame.defaultProps = {
  maxHeight: 75,
  consentNecessary: true
};

export default ResponsiveIFrame;
