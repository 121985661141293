import React, { useEffect, useContext, useState } from 'react';
import { queryCache }                             from 'react-query';

import Overview         from '../Overview/Overview';
import { StoreContext } from '../../context/Store';
import { EApiContext }  from '../../context/EApi';

const BrandPageWrapper = ({ title, category, manufacturer }) => {
  const [hasProducts, setHasProducts] = useState(false);
  const { setManufacturersFilter, setCategoryFilter } = useContext(StoreContext);
  const { fetchProducts, settings } = useContext(EApiContext);

  if (!settings) {
    return null;
  }

  const { data } = settings;

  const prefetchProducts = async (categoryId) => {
    const result = await queryCache.prefetchQuery(['products', {
      categoryFilter: categoryId,
      manufacturersFilter: manufacturer,
      subcategoriesFilter: [],
      sort: '-created_on',
    }, 0], fetchProducts)
    await setHasProducts(result.meta.result_count !== 0);
  }

  useEffect(() => {

    let currentCategory;
    let currentCategoryId;

    if (data) {
      const availableCategories = data.category_filters;
      currentCategory = availableCategories.find(e => e.category_id.slug === category);
      if (currentCategory) {
        currentCategoryId = currentCategory.category_id.id
      }
    }

    let isMounted = true;
    if (isMounted) {
      if (typeof manufacturer !== 'undefined' && typeof category !== 'undefined') {
        prefetchProducts(currentCategoryId || category);
        setManufacturersFilter(manufacturer);
        setCategoryFilter(currentCategoryId || category);
      }
    }

    return () => {
      //cleanup
      isMounted = false;
      queryCache.invalidateQueries('products');
      setManufacturersFilter([]);
      setCategoryFilter(null);
      setHasProducts(false);
    }
    //eslint-disable-next-line
  }, [category, manufacturer, title, data])

  if (!hasProducts) {
    return null;
  }

  return (
    <Overview title={title} brandPageMode={true}/>
  )
};

export default BrandPageWrapper;