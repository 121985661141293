import styled from '@emotion/styled';

export const Consent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 400px;
  padding: 40px;
  > div {
    max-width: 960px;
  }
  h4 {
    margin: 0 0 20px 0;
    font-weight: bold;
  }
  background-color: ${props => props.theme.colors.base['600']};
`;