import React                 from 'react';
import { graphql }           from 'gatsby';
import { Box, Center, Flex } from '@chakra-ui/react';


import { findT }        from '@interness/web-core/src/components/_helpers';
import SEO              from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsSubnavigation
                        from '@interness/brands-addon/src/components/modules/BrandsSubnavigation/BrandsSubnavigation';
import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Logo             from '@interness/web-core/src/components/media/Logo/Logo';
import BrandPageWrapper from '@interness/ecommerce-addon/src/components/BrandPageWrapper/BrandPageWrapper';
import Overridable      from '@interness/web-core/src/components/Overridable/Overridable';
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';

import Gallery      from '../../../../components/Gallery/Gallery';
import CallToAction from '../../../../components/CallToAction/CallToAction';

const BrandPageTemplate = props => {
  const data = props.data.directusBrands.brand;
  const type = props.data.directusBrandTypes;
  const translations = findT(data.translations, props.pageContext.lang);
  const typeTranslations = findT(type.translations, props.pageContext.lang);

  if (!data.gallery_images_square[0]) {
    console.log('Quadratische Bilder fehlen');
  }

  return (
    <>
      <SEO title={data.display_name}/>
      <BrandsSubnavigation type={data.type.type}/>
      <Spacer/>
      <Flex flexWrap="wrap" maxWidth="1024px" m="auto" width="95%" justifyContent="center">
        <Box
          width={['100%', '100%', data.gallery_images_square[0] ? '50%' : '100%', data.gallery_images_square[0] ? '50%' : '100%']}
          mt={['6', '6', '0', '0']}>
          <Center>
            <Box width="240px" height="80px">
              <Logo logo={data.logo.localFile} title={data.display_name} svgWidth="240px" svgHeight="80px"
                    imageWidth="240px"/>
            </Box>
          </Center>
          <Box p={12} textAlign={'justify'} dangerouslySetInnerHTML={{ __html: translations.description }}/>
        </Box>
        {data.gallery_images_square[0] &&
          <Box width={['80%', '80%', '50%', '50%']}>
            <Gallery images={data.gallery_images_square}/>
          </Box>}
      </Flex>
      {data.youtube_video_id &&
        <Box maxWidth="1024px" m="auto" width="95%">
          <ResponsiveIFrame
            src={`https://www.youtube-nocookie.com/embed/${data.youtube_video_id}?rel=0&amp;controls=0`}
            title={`YoutubeVideo`} type={'youtube'}/>
          <Spacer/>
        </Box>}
      <Overridable fieldName="vimeo_video_id" collection="brand" itemId={data.directus_id}
                   type="text_override"
                   render={override => {
                     if (!override && !data.vimeo_video_id) {
                       return null;
                     }
                     return (
                       <Box maxWidth="1024px" m="auto" width="95%">
                         <Spacer/>
                         <ResponsiveIFrame maxHeight={56.25}
                                           src={`https://player.vimeo.com/video/${override ? override : data.vimeo_video_id}?dnt=1`}
                                           title={`VimeoVideo`}
                                           type={'vimeo'}/>
                         <Spacer/>
                       </Box>
                     )
                   }}/>
      <Box maxWidth="1024px" m="auto" width="95%">
        <BrandPageWrapper category={typeTranslations.slug} title={data.display_name} manufacturer={data.slug}/>
      </Box>
      <Spacer/>
      <CallToAction/>
    </>
  )
};

export default BrandPageTemplate;

export const query = graphql`
    query($slug: String!, $type: String!) {
        directusBrands(brand: {slug: {eq: $slug}, type: {type: {eq: $type}}}) {
            ...BrandsCore
            ...BrandGalleryImagesSquareWithThumbnails
        }
        directusBrandTypes(type: {eq: $type}) {
            type
            translations {
                display_name
                slug
                language
            }
        }
        allSitePlugin(filter: {name: {regex: "/@interness/"}}) {
            nodes {
                version
                name
            }
        }
    }
`;