import React                                   from 'react';
import ImageGallery                            from 'react-image-gallery';
import Img                                     from 'gatsby-image';
import { IconButton, useBreakpointValue, Box } from '@chakra-ui/react';
import { IoIosArrowBack, IoIosArrowForward }   from 'react-icons/io';

import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import './styles.css';

const Nav = ({ direction, onClick }) => {
  const size = useBreakpointValue({ sm: 'sm', md: 'md', lg: 'lg' })
  return <IconButton
    sx={{
      position: 'absolute',
      top: '45%',
      zIndex: '2',
      right: direction === 'right' && '-50px',
      left: direction === 'left' && '-50px',
      margin: '0 20px',
      opacity: 0.4,
      ':focus': {
        opacity: 1,
      },
      '.image-gallery-slide-wrapper:hover &': {
        opacity: 1,
      },
    }}
    colorScheme='brand'
    size={size}
    aria-label={direction === 'right' ? 'Nächtes Bild' : 'Vorheriges Bild'}
    isRound
    onClick={onClick}
    icon={direction === 'right' ? <IoIosArrowForward/> : <IoIosArrowBack/>}
  />
}

const buildImageArray = (images) => {
  const arr = [];
  images.filter(image => !!image.file.localFile).forEach(image => {
    const struc = {
      original: image.file.localFile.images.fluid,
      thumbnail: image.file.localFile.thumbnails.fluid,
    };
    arr.push(struc)
  });
  return arr;
};

const Gallery = ({ images }) => {
  const galleryOptions = {
    lazyLoad: true,
    showFullscreenButton: false,
    showPlayButton: false,
    showNav: images.length > 1,
    showThumbnails: images.length > 1,
    defaultImage: 'https://placehold.jp/333/ffffff/800x800.png?text=Kein%20Bild',
    onErrorImageURL: 'https://placehold.jp/333/ffffff/800x800.png?text=Kein%20Bild',
    renderThumbInner: (p) => <Box rounded={'lg'} overflow={'hidden'}><Img fluid={p.thumbnail}/></Box>,
    renderRightNav: (onClick, disabled) => <Nav onClick={onClick} disabled={disabled} direction="right"/>,
    renderLeftNav: (onClick, disabled) => <Nav onClick={onClick} disabled={disabled} direction="left"/>,
    renderItem: (p) => <Box maxW={'700px'} rounded={'2xl'}
                            boxShadow={'2xl'}
                            overflow={'hidden'}><Img fluid={p.original}/></Box>
  };

  return <ImageGallery items={buildImageArray(images)} {...galleryOptions}/>
};

export default Gallery;