import { useStaticQuery, graphql } from 'gatsby';
import PropTypes                   from 'prop-types';

const Overridable = ({ collection, itemId, fieldName, render, type }) => {
  const overrides = useStaticQuery(query).allDirectusOverrides;

  const override = overrides.nodes.filter(node => {
    return node.collection === collection && node.item_id === itemId && node.field_name === fieldName
  })

  if (override.length === 1) {
    const content = override[0][type];
    return render(content)
  } else {
    return render()
  }
};

Overridable.propTypes = {
  collection: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['html_override', 'text_override', 'textarea_override']).isRequired,
};

export default Overridable;

const query = graphql`
    query {
        allDirectusOverrides(filter: {id: {ne: "dummy"}}) {
            nodes {
                collection
                display_name
                field_name
                html_override
                item_id
                text_override
                textarea_override
            }
        }
    }
`;