import React, { useContext, useEffect, useState } from 'react';
import PropTypes                                  from 'prop-types';
import { Button }                                 from '@chakra-ui/react';

import Spacer              from '../../structure/Spacer/Spacer';
import { Link }            from '../../elements';
import routes              from '../../../../config/routes';
import { LanguageContext } from '../../../providers';

import { Consent } from './styles';

const DEFAULT_TEXTS = {
  vimeo: {
    consentHeader: 'Film anzeigen',
    consentText: 'Zum Abspielen unseres Films verwenden wir Vimeo. Bitte beachten Sie, dass hierbei Ihre persönlichen Daten erfasst und gesammelt werden können. Um den Film zu sehen, stimmen Sie bitte zu, dass dieser vom Vimeo-Server geladen wird.',
    consentButton: 'Film anzeigen',
  },
  youtube: {
    consentHeader: 'Film anzeigen',
    consentText: 'Zum Abspielen unseres Films verwenden wir Youtube. Bitte beachten Sie, dass hierbei Ihre persönlichen Daten erfasst und gesammelt werden können. Um den Film zu sehen, stimmen Sie bitte zu, dass dieser vom Youtube-Server geladen wird.',
    consentButton: 'Film anzeigen',
  }
}

const ContentConsent = ({ consentId, consentHeader, consentText, consentButton, showPrivacyLink, type, children }) => {
  const { language } = useContext(LanguageContext);
  //TODO move to global context
  const [consent, setConsent] = useState(false);
  const consentKey = `contentConsent:${consentId.toLowerCase()}`;

  if (type && DEFAULT_TEXTS[type]) {
    consentHeader = DEFAULT_TEXTS[type].consentHeader;
    consentText = DEFAULT_TEXTS[type].consentText;
    consentButton = DEFAULT_TEXTS[type].consentButton;
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const contentConsent = window.localStorage.getItem(consentKey);
      setConsent(!!contentConsent);
    }
    //eslint-disable-next-line
  }, [consentKey]);

  const onAllow = () => {
    setConsent(true);
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(consentKey, '1')
    }
  };

  if (!consent) {
    return (
      <>
        <Spacer/>
        <Consent>
          <div>
            <h4 dangerouslySetInnerHTML={{ __html: consentHeader }}/>
            <p dangerouslySetInnerHTML={{ __html: consentText }}/>
            {showPrivacyLink &&
            <p>Erfahren Sie mehr über Ihren Datenschutz und Widerruf in unserer <Link
              to={routes.privacy[language]}>Datenschutzerklärung.</Link></p>}
            <Button colorScheme="brand" onClick={onAllow}>{consentButton}</Button>
          </div>
        </Consent>
        <Spacer/>
      </>
    )
  } else {
    return (
      <>{children}</>
    )
  }

}

ContentConsent.propTypes = {
  consentId: PropTypes.string.isRequired,
  consentHeader: PropTypes.string,
  consentText: PropTypes.string,
  consentButton: PropTypes.string,
  showPrivacyLink: PropTypes.bool,
  type: PropTypes.oneOf(['youtube', 'vimeo']),
}

ContentConsent.defaultProps = {
  consentHeader: 'Externe Inhalte',
  consentText: 'Möchten Sie die externen Inhalte laden?',
  consentButton: 'Externe Inhalte laden',
  showPrivacyLink: true,
  type: null
}

export default ContentConsent;
